import React, { Component } from 'react';
import MenuSharpIcon from '@material-ui/icons/MenuSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { withAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';

export class Menu extends Component {

    state = {
        menu: ''
    }

    handleMenu = () => {
        this.setState({menu: this.state.menu === '' ? 'open': ''})
    }

    render() {

        const { user } = this.props.auth0;

        return (
            <div className={'menu ' + this.state.menu}>
                <div className="menu-btn" onClick={this.handleMenu}>
                    <MenuSharpIcon />
                </div>
                <div className="menu-container">
                    <div className="menu-bg"></div>
                    <div className={'menu-content-container ' + this.state.menu}>
                        <div className="menu-close" onClick={this.handleMenu}>
                            <CloseSharpIcon />
                        </div>
                        <div className="menu-content">
                            <h2><img className="gravitar" src={user.picture} /><br />
                            {user.nickname}</h2>
                            
                            <ul>
                                <li><LogoutButton /></li>
                                <li>Item 3</li>
                                <li>Item 4</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}



export default withAuth0(Menu);