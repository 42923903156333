import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from "react-router-dom";
import LoginButton from '../LoginButton';
import SignupButton from '../SignupButton';
import Loading from '../Loading';

const Login = () => {
    return (
        <React.Fragment>
            <div className="container login">
                <div className="content">
                    <h1>You are not logged in.</h1>
                    <p>You need to either login or sign up. </p>
                    <p><LoginButton /></p>
                    <p><SignupButton /></p>
                    <p></p>
                </div>
            </div>
        </React.Fragment>
    )
    
}

export default Login;