import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import About from './components/pages/About';
import Home from './components/pages/Home';
import Test from './components/pages/Test';
import Login from './components/pages/Login';
import Loading from './components/Loading';
import ProtectedRoute from "./auth/protected-route";


class App extends Component {

    state = {
        mounted: false
    }
    
    componentDidMount() {
        this.setState({ mounted: true });
    }

    render() {

        let view = '';
        const { isAuthenticated } = this.props.auth0;
        console.log(isAuthenticated) 
        if (isAuthenticated === true && this.state.mounted == true) {
            view = <ProtectedRoute exact path="/" component={Home} />;
        } else if (isAuthenticated === false && this.state.mounted == true) {
            view = <Route exact path="/" component={Login} />
        } else {
            view = <Route exact path="/" component={Loading} />
        }

        return (
            <Router>
                <div className="App">
                    <Switch>
                        {view}
                        <Route path="/about" component={About} />
                        <Route path="/test" component={Test} />
                        <Route path="/login" component={Login} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default withAuth0(App);
